export const videos = {
	"1_2": 551283570,
	"1_2_3": 551278145,
	"1_2_3_4": 551275614,
	"1_2_3_4_5": 551274281,
	"1_2_3_4_6": 551274972,
	"1_2_3_5": 551276853,
	"1_2_3_5_6": 551276221,
	"1_2_3_6": 551277489,
	"1_2_4": 551280864,
	"1_2_4_5": 551279501,
	"1_2_4_5_6": 551278844,
	"1_2_4_6": 551280165,
	"1_2_5": 551282170,
	"1_2_5_6": 551281538,
	"1_2_6": 551282865,
	"1_3": 551284966,
	"1_3_4": 551284256,
	"1_3_4_5": 551249089,
	"1_3_4_5_6": 551250030,
	"1_3_4_6": 551247227,
	"1_3_5": 551248788,
	"1_3_5_6": 551246521,
	"1_3_6": 551245926,
	"1_4": 551234297,
	"1_4_5": 551263127,
	"1_4_5_6": 551271392,
	"1_4_6": 551293335,
	"1_5": 551324649,
	"1_5_6": 551324145,
	"1_6": "", // MISSING
	"2_3": "", // MISSING
	"2_3_4": 551697532,
	"2_3_4_5": 551695357,
	"2_3_4_5_6": 551693381,
	"2_3_4_6": 551691576,
	"2_3_5": 551766917,
	"2_3_5_6": 551769861,
	"2_3_6": 552055282,
	"2_4": 552052903, // https://vimeo.com/552052903/settings
	"2_4_5": 552043555, // https://vimeo.com/552043555/settings
	"2_4_5_6": 552080478, // https://vimeo.com/552080478/settings
	"2_4_6": 552179417, // https://vimeo.com/552179417/settings
	"2_5": 552177621, // https://vimeo.com/552177621/settings
	"2_5_6": 552185131, // https://vimeo.com/552185131/settings
	"2_6": 552236746, // https://vimeo.com/552236746/settings
	"3_4": 552234121, // https://vimeo.com/552234121/settings
	"3_4_5": 552447891, // https://vimeo.com/552447891/settings
	"3_4_5_6": 552445194, // https://vimeo.com/552445194/settings
	"3_4_6": 552442399, // https://vimeo.com/552442399/settings
	"3_5": 552439628, // https://vimeo.com/552439628/settings
	"3_5_6": 552505915, // https://vimeo.com/552505915/settings
	"3_6": 553411903, // https://vimeo.com/553411903/settings
	"4_5": 552549622, // https://vimeo.com/552549622/settings
	"4_5_6": 553400231, // https://vimeo.com/553400231/settings
	"4_6": 553390033, // https://vimeo.com/553390033/settings
	"5_6": 553432750 // https://vimeo.com/553432750/settings
};
