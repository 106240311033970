import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as styles from './index.module.css';
import { videos } from './videos';

const useStyles = makeStyles(() =>
	createStyles({
		formControl: {
			font: 'Aquino',
			fontSize: '12px'
		}
	}),
);

const App = () => {
	const classes = useStyles();
	const playerRef = React.useRef(null);
	const [lines, setLines] = React.useState([]);
	const [media, setMedia] = React.useState('');
	const [infoDialogVisible, setInfoDialog] = React.useState(false);

	const handleChange = (e) => {
		const line = parseInt(e.target.name, 10);
		const checked = e.target.checked;

		let newLines = [...lines];
		if (checked) {
			newLines.push(line);
		} else {
			newLines = newLines.filter((i) => i !== line);
		}

		setLines(newLines);
	};

	const play = () => {
		const media = [...lines].sort((a, b) => a - b);
		setMedia(media.join('_'));
	};

	const optionsDisabled = lines.length >= 5;
	const btnDisabled = lines.length < 2;

	if (media) {
		const videoFile = videos[media];
		return (
			<div className={styles.player}>
				<a href="#" className={styles.close} onClick={() => setMedia('')}>Close</a>
				<iframe
					ref={playerRef}
					src={`https://player.vimeo.com/video/${videoFile}?autoplay=1&playsinline=0&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					className={styles.iframe} />
			</div>
		);
	}

	return (
		<section className={styles.page}>
			<aside>
				<div className={styles.block}>
					<div>COMPOSITION</div>
					<div>ANDRÉ CORMIER</div>
				</div>

				<div className={styles.block}>
					<div>CONCEPTION</div>
					<div>ANDRÉ CORMIER</div>
				</div>

				<div className={styles.block}>
					<div>RÉALISATEUR<span className={styles.i18nPad} />PRODUCER</div>
					<div>ANDRÉ CORMIER</div>
				</div>

				<div className={styles.block}>
					<div>DIRECTEUR DE LA PHOTOGRAPHIE<span className={styles.i18nPad} />DIRECTOR OF PHOTOGRAPHY</div>
					<div>MATHIEU LÉGER</div>
				</div>

				<div className={styles.block}>
					<div>MUSICIENS<span className={styles.i18nPad} />MUSICIANS</div>
					<div>FLÛTES<span className={styles.i18nPadSmall} />KARIN AURELL<span className={styles.i18nPadSmall} />FLUTES</div>
					<div>CLARINETTES<span className={styles.i18nPadSmall} />ANDREW GEORGE<span className={styles.i18nPadSmall} />CLARINETS</div>
					<div>VIOLON<span className={styles.i18nPadSmall} />JEFFERY BAZETT JONES<span className={styles.i18nPadSmall} />VIOLIN</div>
					<div>ALTO<span className={styles.i18nPadSmall} />ROBIN STREB<span className={styles.i18nPadSmall} />VIOLA</div>
					<div>VIOLONCELLE<span className={styles.i18nPadSmall} />JAEYOUNG CHONG<span className={styles.i18nPadSmall} />CELLO</div>
					<div>CONTREBASSE<span className={styles.i18nPadSmall} />ANDREW REED MILLER<span className={styles.i18nPadSmall} />DOUBLE BASS</div>
					<div>GUITARES<span className={styles.i18nPadSmall} />DAVID GREGORY<span className={styles.i18nPadSmall} />GUITARS</div>
				</div>

				<div className={styles.block}>
					<div>INGÉNIEUR DU SON<span className={styles.i18nPad} />SOUND ENGINEER</div>
					<div>LÉANDRE BOURGEOIS</div>
				</div>

				<div className={styles.block}>
					<div>INGÉNIEUR DU WEB<span className={styles.i18nPad} />WEB ENGINEER</div>
					<div>BEN KEEN</div>
				</div>

				<div className={styles.block}>
					<div>MONTAGE ET COULEUR<span className={styles.i18nPad} />EDITING AND COLOUR</div>
					<div>EMMANUEL ALBERT</div>
				</div>

				<div className={styles.block}>
					<div>ENREGISTRÉE DANS LA SALLE NEIL MICHAUD À L’UNIVERSITÉ DE MONCTON EN ACADIE. JANVIER LE 6 AU 12, 2020</div>
				</div>

				<div className={styles.block}>
					<div>REMERCIEMENTS SPÉCIALS<span className={styles.i18nPad} />SPECIAL THANKS</div>
					<div>ROB ROBICHAUD AND HEATHER LEWIS</div>
					<div>LÉANDRE BOURGEOIS</div>
					<div>ROBIN STREB</div>
					<div>TED CORMIER</div>
					<div>MATHIEU LÉGER</div>
					<div>DAVID GREGORY</div>
					<div>MONIQUE RICHARD</div>
					<div>GSN</div>
					<div>ANNIE FRANCE NÖEL</div>
					<div>CATHERINE ARSENEAULT</div>
					<div>ANGIE RICHARD</div>
					<div>MARC SNAKE LANDRY</div>
					<div>ELISE ANNE LAPLANTE</div>
					<div>CANADA COUNCIL FOR THE ARTS</div>
					<div>ARTSNB</div>
					<div>GSN</div>
				</div>

				<div className={styles.block}>
				INSITU•2019 COPYRIGHT 2019
				</div>

				<div className={styles.block}>
					<a href="mailto:info@emsis.ca">infO@emsis.ca</a>
				</div>

				<div className={styles.onlineTime}>
					<div>Site en ligne jusqu'au: 28 mai 2022</div>
					<div>Site Online until: May 28, 2022</div>
				</div>
			</aside>
			<main>
				<header>
					<h1>in<span>S</span>itu•2019</h1>
					<h3>Lat, LOng, 46.094355, -64.781927</h3>
					<h3>en mémOire de RObert</h3>
				</header>

				<div className={styles.descBlock}>
					Cette pièce comprend six duos - flûte et guitare; clarinette et guitare; violon et guitare; alto et
					guitare; violoncelle et guitare; contrebasse et guitare - ceux-ci constituent le matériel de toute
					la pièce. Toute combinaison de ces duos peut être jouée ensemble en tant qu’ensemble - avec un
					minimum de deux duos et un maximum de cinq duos.
					<div className={styles.actionBlock}>
						Sélectionnez vous-même les duos que vous souhaitez entendre en tant qu’ensemble ci-dessous.
					</div>
				</div>

				<div className={styles.descBlock}>
					This piece includes six duos — flute and guitar; clarinet and guitar; violin and guitar; viola and
					guitar; cello and guitar; contrabass and guitar — these make up the material for the entire piece.
					Any combination of these duos can be played together as an ensemble — with a minimum of two duos,
					and a maximum of five duos.
					<div className={styles.actionBlock}>
						Select for yourself which duos you would like to hear together as an ensemble below.
					</div>
				</div>

				<div className={styles.controls}>
					<FormControl component="fieldset" className={classes.formControl}>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={lines.indexOf(1) !== -1}
										onChange={handleChange}
										name="1"
										disabled={lines.indexOf(1) === -1 && optionsDisabled}
									/>
								}
								label="flûte et guitare | flute and guitar"
							/>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={lines.indexOf(2) !== -1}
										onChange={handleChange}
										name="2"
										disabled={lines.indexOf(2) === -1 && optionsDisabled}
									/>
								}
								label="clarinette et guitare | clarinet and guitar"
							/>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={lines.indexOf(3) !== -1}
										onChange={handleChange}
										name="3"
										disabled={lines.indexOf(3) === -1 && optionsDisabled}
									/>
								}
								label="violon et guitare | violin and guitar"
							/>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={lines.indexOf(4) !== -1}
										onChange={handleChange}
										name="4"
										disabled={lines.indexOf(4) === -1 && optionsDisabled}
									/>
								}
								label="alto et guitare | viola and guitar"
							/>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={lines.indexOf(5) !== -1}
										onChange={handleChange}
										name="5"
										disabled={lines.indexOf(5) === -1 && optionsDisabled}
									/>
								}
								label="violoncelle et guitare | cello and guitar"
							/>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={lines.indexOf(6) !== -1}
										onChange={handleChange}
										name="6"
										disabled={lines.indexOf(6) === -1 && optionsDisabled}
									/>
								}
								label="contrebasse et guitare | double bass"
							/>
						</FormGroup>
					</FormControl>
				</div>

				<Button
					color="primary"
					variant="contained"
					disabled={btnDisabled}
					className={styles.playButton}
					onClick={play}
				>
					Jouer | Play
				</Button>


				<footer>
					<ul>
						<li><img src="./resources/CCA_RGB_white_f.png" width="140" /></li>
						<li><img src="./resources/artsNB-transparent.png" width="140" /></li>
						<li><img src="./resources/GSN_logo.png" width="55" /></li>
						<li><img src="./resources/refluxlogoPNG.gif" width="55" /></li>
					</ul>

					<div className={styles.moreInfo} onClick={() => setInfoDialog(true)}>
						<span className={styles.block}>
							<span>pour plus d’informations</span>
							<span>sur inSitu•2019</span>
						</span>
						<span>
							<span>for more information on</span>
							<span>inSitu•2019</span>
						</span>
					</div>

					<a href="./resources/InSitu2019-score.pdf" target="_blank" className={`${styles.moreInfo} ${styles.viewScore}`}>
						<span>Pour voir la partition</span>
						<span>See the score</span>
					</a>

				</footer>
			</main>

			<Dialog
				className={styles.infoDialog}
				open={infoDialogVisible}
				onClose={() => setInfoDialog(false)}>
				<DialogTitle className={styles.title}>
					<span>InSitu 2019</span>
					<IconButton aria-label="close" onClick={() => setInfoDialog(false)}>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<p>
						<i>inSitu•2019</i> est une composition inspirée des changements saisonniers sur une période de
						365 jours. Cette pièce de 78 minutes transforme en musique divers événements météorologiques,
						activités écologiques et changements de lumière du jour vécus dans le sud-est du Nouveau-Brunswick
						(Latitude 46.094355, Longitude -64.781927). La composition codifie une année de données
						recueillies afin de déterminer les paramètres musicaux des instruments (par exemple: c’est la
						température qui contrôle la hauteur des notes : le froid = notes aiguës, la chaleur = notes
						graves). Le résultat est une impression musicale / sonore à petite échelle de l’année 2019. La
						pièce est écrite pour six guitares en duo avec six autres instruments. Les guitares fonctionnent
						comme une grande horloge abstraite. Pour les performances, n’importe lequel sous-ensemble de ces
						duos peut être réalisé ensemble, cependant jamais un seul duo, et jamais les six duos. La pièce
						musicale est présentée avec une composante audiovisuelle ayant été captée dans la nature, à
						côté d’un ruisseau, près de Moncton, à des intervalles mensuelles au cours de 2019. <i>inSitu•2019</i>
						crée ainsi une impression musicale de l’endroit où nous vivons et comment « cet endroit » est
						affecté par les conséquences de l’orbite de la Terre autour du Soleil. Son objectif est de nous
						donner l’occasion de reconnaître et de recontextualiser notre environnement sur des plans
						auditifs et visuels, et peut-être de nous y voir reflétés. L’œuvre provoque également une
						réflexion sur les effets impitoyables que le temps pose à nos environnements fragiles.
					</p>
					<p>
						Pour créer <i>inSitu•2019</i>, des événements et des variables météorologiques observables ont été
						collectionnées à 6 points de la journée à des intervalles de 4 heures chaque jour de 2019. Chaque
						instrument reçoit une note par jour. La manière ou les techniques de jeu qui ornent les notes
						sont le résultat d'un système développé pour articuler ou exprimer ces variables météorologiques
						sous forme de musique. Le savoir pourrait vous rendre une écoute plus intéressant ou vous aider
						à comprendre le contexte.
					</p>
					<p>
						Les parties de guitare sont une affaire entièrement différente. Chaque guitare a son propre
						accord, elles sont jouées sur des cordes vides et fonctionnent comme une horloge.
					</p>
					<br />

					<hr size="1" />
					<br />

					<p>
						<i>inSitu•2019</i> is composition inspired by seasonal changes over a 365-day period. This
						78-minute piece transforms into music various weather events, ecological activities, and daylight
						shifts experienced in Southeast New Brunswick (Latitude 46.094355, Longitude -64.781927). The
						composition codes a year’s worth of collected data to control all the instruments’ musical
						parameters (for example: temperature controls pitch range: cold = high pitch, warm = lower pitch).
						The result is a micro-scaled musical/sonic impression of 2019. The piece is scored for six guitars
						that are in duos with six instruments. The guitars function as a big abstract clock. For
						performance, any subset of these duos can be performed together—however, never just one, and
						never, all six. The piece is accompanied by an audiovisual field recording, which was collected
						in nature, beside a brook, near Moncton, at monthly intervals over the course of 2019.
						<i>inSitu•2019</i> creates a musical impression of where we live, and how “this place” is affected
						by the consequence of Earth’s orbit around the Sun. Its aim is to give us a chance to recognize
						and recontextualize our surroundings aurally and visually, and possibly see a reflection of
						ourselves. The work also provokes contemplation regarding how weather is relentless for our
						fragile environments.
					</p>
					<p>
						To make inSitu•2019, observable weather events and variables were collected at 6 points during
						the day in intervals of 4 hours on each day of 2019. Each instrument gets one note per day. The
						manner or playing techniques that decorate the notes is the result of a system developed to
						articulate or express these weather variables as music. Knowing this might make it more
						interesting or help you understand the context.
					</p>
					<p>
						The guitar parts are an entirely different affair. Each guitar has their own tuning, they are
						played on open strings, and operate as a clock.
					</p>

					<p>
						<a href="mailto:info@emsis.ca">André Cormier</a>
					</p>
				</DialogContent>
			</Dialog>

		</section>
	);
};

export default App;
